import Ajax from '../modules/ajax';

export default class {
    ajax;

    constructor() {
        this.ajax = new Ajax;
        this.listen();
    }

    setFormSent(form) {
        $('#modalSuccess').modal('show');
        form.element.querySelectorAll('.label-wrap').forEach(wrap => {
            wrap.classList.remove('filled');
        })
        form.element.querySelectorAll('.form-control').forEach(input => {
            input.value = '';
        });
    };

    listen() {
        let callbackCall = document.querySelectorAll('.js_form-callback-call');

        if (callbackCall.length) {
            $(callbackCall).parsley().on('form:submit', (form) => {
                let data = {
                    action: 'addReview',
                    form: {
                        company: form.element.querySelector('[name="name-company"]').value,
                        phone: form.element.querySelector('[name="phone"]').value
                    }
                }

                this.ajax.send(data, (response) => {
                    if (response.data.result === 'success') {
                        this.setFormSent(form);
                    }
                });
                return false;
            });
        }
    }
}





