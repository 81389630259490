import Select from '../components/Select';
import axios from 'axios';
import Vue from 'vue';

export default class {
    app;

    constructor() {
        if (!document.getElementById('appProjects')) {
            return;
        }
        this.app = new Vue({
            el: '#appProjects',
            delimiters: ['{%', '%}'],
            components: {
                'select2': Select,
            },
            data: {
                activeCountry: null,
                activeCity: null,
                allClients: null,
                allCountries: null,
                allProducts: null,
                currentCountry: 'all',
                currentProductType: 'all',
            },
            methods: {
                clientsSummary: function (countryAlias) {
                    var a = [];
                    for (var el in this.allClients) {
                        if (this.allClients[el].country === countryAlias) {
                            for (var pt in this.allClients[el].productTypes) {
                                var current = -1;
                                for (var i = 0; i < a.length; i++) {
                                    if (a[i].alias === this.allClients[el].productTypes[pt]) {
                                        current = i;
                                        break;
                                    }
                                }
                                if (current < 0) {
                                    var data = this.getProductData(this.allClients[el].productTypes[pt]);
                                    data.count = 1;
                                    a.push(data);
                                } else {
                                    a[current].count++;
                                }
                            }
                        }
                    }
                    return a;
                },
                getProductData: function (productType) {
                    for (var pt in this.allProducts) {
                        if (this.allProducts[pt].alias === productType) {
                            return this.allProducts[pt];
                        }
                    }
                    return [];
                },
                setCurrentCounty: function (e, country) {
                    e.preventDefault();
                    this.currentCountry = country;
                },
                setCurrentProductType: function (e, type) {
                    e.preventDefault();
                    this.currentProductType = type;
                }
            },
            computed: {
                countriesList: function () {
                    var arr = [];
                    for (var c in this.allCountries) {
                        var el = this.allCountries[c];
                        el.select = (this.currentCountry === el.alias) ? 1 : 0;
                        arr.push(el);
                    }
                    return arr;
                },
                countries: function () {
                    var arr = [];
                    for (var c in this.allCountries) {
                        if (this.currentCountry !== 'all' && this.currentCountry !== this.allCountries[c]
                            .alias) {
                            continue;
                        }
                        var el = this.allCountries[c];
                        el.items = [];
                        for (var i in this.allClients) {
                            if (this.currentProductType !== 'all') {
                                var x = 0;
                                for (var z = 0; z < this.allClients[i].productTypes.length; z++) {
                                    var v = this.allClients[i].productTypes[z];
                                    if (this.currentProductType === v) {
                                        x++;
                                    }
                                }
                                if (x < 1) continue;
                            }
                            if (this.allClients[i].country === this.allCountries[c].alias) {
                                el.items.push(this.allClients[i]);
                            }
                        }
                        if (el.items.length) {
                            arr.push(el);
                        }
                    }
                    arr = arr.sort(function (a, b) {
                        return b.items.length - a.items.length;
                    });
                    return arr;
                }
            },
            mounted() {
                // ! изначально на странице отрисовываются 6 проектов в 3 строки по 2 шт
                // ! по кнопке подгружается по 2 строки
                axios.get('/countries.json').then(response => (this.allCountries = response.data.data));
                axios.get('/products.json').then(response => (this.allProducts = response.data.data));
                axios.get('/clients.json', {
                    params: {
                        page: 1,
                        per_page: 8
                    }
                }).then(response => (this.allClients = response.data.data));
            },
        });
    }
}
