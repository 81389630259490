window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.$ = window.JQuery = require('jquery');
require('popper.js');
require('bootstrap');
require('animate.css');
const WOW = require('wowjs'); window.wow = new WOW.WOW({ live: false });
require('slick-carousel/slick/slick.min.js');
require('particles.js');
require('hamburgers');
require('parsleyjs');
require('typed.js');
require('lazysizes');
require('select2/dist/js/select2');
require('evoweb-geoxml3/polys/geoxml3');
// require('evoweb-geoxml3/ProjectedOverlay');

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });