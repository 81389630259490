export default class {
    webinarButtons = document.querySelectorAll('.js_timetable-button');

    constructor() {
        if (!this.webinarButtons.length) {
            return;
        }

        this.listen()
    }

    listen() {
        this.webinarButtons.forEach(btn => {
            btn.addEventListener('click', () => {
                window.livewire.emit('clickWebinarModalForm', btn.dataset.webinarid)
            });
        });
    }
}
