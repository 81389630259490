export default class {
    item = document.querySelectorAll('.advance-item');
    itemResume = document.querySelector('.advance-resume');
    mobileWidth = innerWidth < 724;
    smallWidth = innerWidth >= 724 && innerWidth < 960 && !((this.item.length - 1) % 2 == 0);
    mediumWidth = innerWidth >= 960 && innerWidth < 1140 && !((this.item.length - 1) % 3 == 0);
    desktopWidth = innerWidth >= 1354 && !((this.item.length - 1) % 4 == 0);

    constructor() {
        if (!this.item.length) {
            return;
        }
        this.removePlug();
        this.condition();
        this.listen();
    }

    removePlug() {
        this.itemResume.classList.add('d-none');
    }

    addPlug() {
        this.itemResume.classList.remove('d-none');
    }

    condition() {
        if (this.desktopWidth || this.mediumWidth || this.smallWidth ) {
            this.addPlug();
        } else if (this.mobileWidth) {
            this.removePlug();
        }
    }

    listen() {
        window.addEventListener('resize', () => {
            this.condition();
        });
    }
}