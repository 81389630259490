import Animate from '../modules/animate';
export default class {
    animate;
    constructor() {
        if (!$('.js_clients-prod').length && !$('.js_clients-desc').length) {
            return;
        }

        this.animate = new Animate();

        $('.js_clients-prod').slick({
            autoplay: true,
            autoplaySpeed: 3500,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            focusOnSelect: true,
            dots: false,
            arrows: false,
            prevArrow:"<button type='button' class='slick-prev'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92.7 185.8' xml:space='preserve'><path d='M17.3 185.8C11.5 180.5 5.8 175.3 0 170c1-1.2 1.9-2.3 2.8-3.5l19.8-24.3c6.5-8 13.1-16.1 19.6-24.1 6.1-7.5 12.2-15.1 18.4-22.6.6-.7 1.1-1.4 1.7-2.1.3-.3.3-.6 0-.9-4.6-5.7-9.3-11.4-13.9-17-5.5-6.8-11-13.6-16.5-20.3-5.3-6.7-10.7-13.2-16-19.7C10.7 29 5.5 22.6.2 16.1c0 0-.1-.1-.2-.3C5.8 10.6 11.5 5.3 17.3 0c.8 1 1.6 1.9 2.3 2.8 7 8.6 14.1 17.3 21.1 25.9 6.5 8 13.1 16.1 19.6 24.1C66.8 60.9 73.4 69 79.9 77c4.2 5.1 8.3 10.3 12.5 15.4.3.4.3.6 0 1-5.5 6.7-11 13.5-16.5 20.2-6.3 7.8-12.7 15.6-19 23.4-6.1 7.6-12.3 15.1-18.4 22.7-6.5 8.1-13.1 16.1-19.6 24.2-.5.6-1 1.2-1.6 1.9z'/></svg></button>",
            nextArrow:"<button type='button' class='slick-next'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92.7 185.8' xml:space='preserve'><path d='M17.3 185.8C11.5 180.5 5.8 175.3 0 170c1-1.2 1.9-2.3 2.8-3.5l19.8-24.3c6.5-8 13.1-16.1 19.6-24.1 6.1-7.5 12.2-15.1 18.4-22.6.6-.7 1.1-1.4 1.7-2.1.3-.3.3-.6 0-.9-4.6-5.7-9.3-11.4-13.9-17-5.5-6.8-11-13.6-16.5-20.3-5.3-6.7-10.7-13.2-16-19.7C10.7 29 5.5 22.6.2 16.1c0 0-.1-.1-.2-.3C5.8 10.6 11.5 5.3 17.3 0c.8 1 1.6 1.9 2.3 2.8 7 8.6 14.1 17.3 21.1 25.9 6.5 8 13.1 16.1 19.6 24.1C66.8 60.9 73.4 69 79.9 77c4.2 5.1 8.3 10.3 12.5 15.4.3.4.3.6 0 1-5.5 6.7-11 13.5-16.5 20.2-6.3 7.8-12.7 15.6-19 23.4-6.1 7.6-12.3 15.1-18.4 22.7-6.5 8.1-13.1 16.1-19.6 24.2-.5.6-1 1.2-1.6 1.9z'/></svg></button>",
            mobileFirst: true,
            waitForAnimate: true,
            asNavFor: '.js_clients-desc',
            responsive: [{
                    breakpoint: 485,
                    settings: {
                        slidesToShow: 3,
                        arrows: true,
                    }
                },
                {
                    breakpoint: 959,
                    settings: {
                        centerMode: false,
                        slidesToShow: 4,
                        arrows: true,
                    }
                }
            ]
        });
        $('.js_clients-desc').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            asNavFor: '.js_clients-prod',
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            waitForAnimate: true,
            draggable: false,
        });

        this.listen();
        this.showSlide(0);
    }

    listen() {
        $('.js_clients-desc').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            this.hideSlide(currentSlide);
            this.showSlide(nextSlide);
        });

        $('.js_clients-prod').on('init', function (slick) {
            $('.js_clients-prod').css('opacity', 1);
        });
    }

    showSlide(index) {
        let slider = document.querySelector('.js_clients-desc');
        let slide = slider.querySelector('[data-slick-index="' + index + '"]');
        let img = slide.querySelector('.desc-content');
        let desc = slide.querySelector('.desc-container');

        this.animate.css(img, 'fadeIn', () => {
            img.classList.add('active');
        });
        this.animate.css(desc, 'fadeInUp', () => {
            desc.classList.add('active');
        });
    }

    hideSlide(index) {
        let slider = document.querySelector('.js_clients-desc');
        let slide = slider.querySelector('[data-slick-index="' + index + '"]');
        let img = slide.querySelector('.desc-content');
        let desc = slide.querySelector('.desc-container');

        this.animate.css(img, 'fadeOut', () => {
            img.classList.remove('active');
        });
        this.animate.css(desc, 'fadeOutUp', () => {
            desc.classList.remove('active');
        });
    }
}