export default class {
    scrollButtons = [];

    constructor() {
        this.scrollButtons = document.querySelectorAll('.js_scroll')
        if (!this.scrollButtons.length) {
            return;
        }
        this.listen();
    }

    listen() {
        this.scrollButtons.forEach(el => {
            el.addEventListener('click', (event) => {
                event.preventDefault();
                const dataScrollTarget = event.target.dataset.scrollTarget;
                if (!dataScrollTarget || dataScrollTarget === '') {
                    return
                }
                const scrollTarget = document.querySelector(dataScrollTarget);
                if (!scrollTarget) {
                    return;
                }
                this.scrollTo(scrollTarget);
            })
        });
    }

    scrollTo(scrollTarget) {
        const headerStickyTop = document.querySelector('.sticky-top');
        const headerOffsetHeight = headerStickyTop.offsetHeight;
        const sectionTimetableOffsetParent = scrollTarget.offsetTop - headerOffsetHeight;

        window.scrollTo({
            top: sectionTimetableOffsetParent,
            left: 0,
            behavior: 'smooth'
        });
    }
}
