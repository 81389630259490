export default class {
    timetable = []

    constructor() {
        this.timetable = document.querySelectorAll('.js_timetable')
        if (!this.timetable.length) {
            return;
        }
        this.listen();
    }

    listen() {
        this.timetable.forEach(el => {
            el.addEventListener('click', (event) => {
                if (this.isButton(event.target)) {
                    return false
                }
                this.isOpen(event.currentTarget)
                    ? this.close(event.currentTarget)
                    : this.open(event.currentTarget);
            })
        })
    };

    isButton(element) {
        return element.classList.contains('timetable-button');
    }

    isOpen(element) {
        const timetableDescriptionWrapper = element.querySelector('.timetable-description__wrapper');
        return timetableDescriptionWrapper.classList.contains('open');
    }

    close(element) {
        const timetableDescriptionWrapper = element.querySelector('.timetable-description__wrapper');
        timetableDescriptionWrapper.classList.remove('open');
        timetableDescriptionWrapper.style.height = '0px';

        element.classList.remove('open');

        const timetableHeaderTitle = element.querySelector('.timetable-header__title');
        timetableHeaderTitle.style.textDecorationLine = 'underline';
    }

    open(element) {
        this.closeAll(element);
        const timetableDescriptionWrapper = element.querySelector('.timetable-description__wrapper');
        timetableDescriptionWrapper.classList.add('open');
        timetableDescriptionWrapper.style.height = `${timetableDescriptionWrapper.scrollHeight}px`;

        element.classList.add('open');

        const timetableHeaderTitle = element.querySelector('.timetable-header__title');
        timetableHeaderTitle.style.textDecorationLine = 'none';
    }

    closeAll(element) {
        const openedElements = document.querySelectorAll('.timetable-description__wrapper');
        openedElements.forEach((element) => {
            const parent = element.closest('.timetable');
            if (this.isOpen(parent)) {
                this.close(parent);
            }
        });
    }
}
