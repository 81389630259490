export default class {
    copyBtns = document.querySelectorAll('.js_copy-link');
    
    constructor() {
        this.listen();
    }

    copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    listen() {
        this.copyBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                const link = btn.parentElement.previousElementSibling;
                const href = link.getAttribute('href');

                this.copyToClipboard(href)
                    .then(() => console.log('text copied'))
                    .catch(() => console.log('copy error'));
            });
        })
    }
}
