export default class {
    modul = document.querySelectorAll('.moduls-list .modul');

    constructor() {
        if (!this.modul.length) {
            return;
        }

        this.listen()
    }
    listen() {
        if (document.documentElement.clientWidth < 724) {
            this.modul.forEach(el => {
                el.addEventListener('click', () => {
                    el.classList.toggle('desc');
                    $(el).siblings().removeClass('desc');
                });
            }); 
        }
    }
}