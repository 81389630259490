import Animate from '../modules/animate';
export default class {
    state = 'closed';
    gutters = 75;
    baseWidth = 42;
    breakpoint = 764;
    maxFixedWidth = 432;
    animate;

    constructor() {
        this.animate = new Animate;
        this.listen();
    }

    setFullSize() {
        let displayWidth = $(window).width();
        let width   = $('.js_header-inner').outerWidth() - this.gutters;
        if (displayWidth >= this.breakpoint) {
            width = this.maxFixedWidth;
        }
        $('.js_search-input').outerWidth(width);
    }

    open() {
        this.setFullSize();
        $('.js_search').removeClass('closed').addClass('opened');
        this.animate.css('.js_search-loupe', 'fadeInLeft');
        this.state = 'opened';
    }

    close() {
        $('.js_search-input').outerWidth(this.baseWidth);
        $('.js_search').removeClass('opened');
        this.animate.css('.js_search-loupe', 'fadeOutLeft', () => {
            $('.js_search').addClass('closed');
        });
        this.state = 'closed';
    }

    listen() {
        $('.js_open-search').on('click', () => {
            (this.state === 'closed') 
                ? this.open() 
                : this.close();
        });
        $('.js_search_submit').on('click', () => {
            $('.js_search-form').submit();
        });
        $(window).on('resize', () => {
            (this.state === 'opened') ? this.close() : null;
        });
    }
} 
