import Typed from 'typed.js';

export default class {
    typed;
    constructor() {
        if(!$('#typed').length) {
            return;
        }
        
        this.typed = new Typed('#typed', {
            stringsElement: '#typed-strings',
            typeSpeed: 70,
            backSpeed : 50, 
            loop: true,
        });
    }    
 }

  
  