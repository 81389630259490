export default class {
    send = function (data, success, fail) {
        success = success || function (response) {
            console.log(response)
        };
        fail = fail || function (error) {
            console.log(error)
        };
        return axios.post('/ajax', data)
            .then(success)
            .catch(fail);
    }
}