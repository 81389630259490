export default class {
    constructor() {
       this.listen();
    }

    listen() {
        this.addEventFocusInput();

        window.addEventListener('addEventFocusInput', (...$param) => {
            this.addEventFocusInput();
        });
    }

    addEventFocusInput() {
        document.querySelectorAll('.form-control').forEach((item) => {
            item.addEventListener('focus', () => {
                item.parentElement.classList.add('filled');
            });

            item.addEventListener('blur', () => {
                item.parentElement.classList.remove('filled');
                if (!item.value == '') {
                    item.parentElement.classList.add('filled');
                }
            });
        });
    }
 }
