export default class {
    constructor() {
        $('.js_projects').each(function () {
            if (!$(this).hasClass('projects-slider--client')) {
                $(this).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
                mobileFirst: true,
                draggable: false,
                prevArrow:"<button type='button' class='slick-prev'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92.7 185.8' xml:space='preserve'><path d='M17.3 185.8C11.5 180.5 5.8 175.3 0 170c1-1.2 1.9-2.3 2.8-3.5l19.8-24.3c6.5-8 13.1-16.1 19.6-24.1 6.1-7.5 12.2-15.1 18.4-22.6.6-.7 1.1-1.4 1.7-2.1.3-.3.3-.6 0-.9-4.6-5.7-9.3-11.4-13.9-17-5.5-6.8-11-13.6-16.5-20.3-5.3-6.7-10.7-13.2-16-19.7C10.7 29 5.5 22.6.2 16.1c0 0-.1-.1-.2-.3C5.8 10.6 11.5 5.3 17.3 0c.8 1 1.6 1.9 2.3 2.8 7 8.6 14.1 17.3 21.1 25.9 6.5 8 13.1 16.1 19.6 24.1C66.8 60.9 73.4 69 79.9 77c4.2 5.1 8.3 10.3 12.5 15.4.3.4.3.6 0 1-5.5 6.7-11 13.5-16.5 20.2-6.3 7.8-12.7 15.6-19 23.4-6.1 7.6-12.3 15.1-18.4 22.7-6.5 8.1-13.1 16.1-19.6 24.2-.5.6-1 1.2-1.6 1.9z'/></svg></button>",
                nextArrow:"<button type='button' class='slick-next'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92.7 185.8' xml:space='preserve'><path d='M17.3 185.8C11.5 180.5 5.8 175.3 0 170c1-1.2 1.9-2.3 2.8-3.5l19.8-24.3c6.5-8 13.1-16.1 19.6-24.1 6.1-7.5 12.2-15.1 18.4-22.6.6-.7 1.1-1.4 1.7-2.1.3-.3.3-.6 0-.9-4.6-5.7-9.3-11.4-13.9-17-5.5-6.8-11-13.6-16.5-20.3-5.3-6.7-10.7-13.2-16-19.7C10.7 29 5.5 22.6.2 16.1c0 0-.1-.1-.2-.3C5.8 10.6 11.5 5.3 17.3 0c.8 1 1.6 1.9 2.3 2.8 7 8.6 14.1 17.3 21.1 25.9 6.5 8 13.1 16.1 19.6 24.1C66.8 60.9 73.4 69 79.9 77c4.2 5.1 8.3 10.3 12.5 15.4.3.4.3.6 0 1-5.5 6.7-11 13.5-16.5 20.2-6.3 7.8-12.7 15.6-19 23.4-6.1 7.6-12.3 15.1-18.4 22.7-6.5 8.1-13.1 16.1-19.6 24.2-.5.6-1 1.2-1.6 1.9z'/></svg></button>",
                responsive: [{
                        breakpoint: 723,
                        settings: {
                            dots: false,
                            arrows: true,
                        }
                    },
                    {
                        breakpoint: 959,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: false,
                            arrows: true,
                        }
                    }
                ]
                });
            } else {
                $(this).slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    mobileFirst: true,
                    draggable: false,
                    prevArrow:"<button type='button' class='slick-prev'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92.7 185.8' xml:space='preserve'><path d='M17.3 185.8C11.5 180.5 5.8 175.3 0 170c1-1.2 1.9-2.3 2.8-3.5l19.8-24.3c6.5-8 13.1-16.1 19.6-24.1 6.1-7.5 12.2-15.1 18.4-22.6.6-.7 1.1-1.4 1.7-2.1.3-.3.3-.6 0-.9-4.6-5.7-9.3-11.4-13.9-17-5.5-6.8-11-13.6-16.5-20.3-5.3-6.7-10.7-13.2-16-19.7C10.7 29 5.5 22.6.2 16.1c0 0-.1-.1-.2-.3C5.8 10.6 11.5 5.3 17.3 0c.8 1 1.6 1.9 2.3 2.8 7 8.6 14.1 17.3 21.1 25.9 6.5 8 13.1 16.1 19.6 24.1C66.8 60.9 73.4 69 79.9 77c4.2 5.1 8.3 10.3 12.5 15.4.3.4.3.6 0 1-5.5 6.7-11 13.5-16.5 20.2-6.3 7.8-12.7 15.6-19 23.4-6.1 7.6-12.3 15.1-18.4 22.7-6.5 8.1-13.1 16.1-19.6 24.2-.5.6-1 1.2-1.6 1.9z'/></svg></button>",
                    nextArrow:"<button type='button' class='slick-next'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92.7 185.8' xml:space='preserve'><path d='M17.3 185.8C11.5 180.5 5.8 175.3 0 170c1-1.2 1.9-2.3 2.8-3.5l19.8-24.3c6.5-8 13.1-16.1 19.6-24.1 6.1-7.5 12.2-15.1 18.4-22.6.6-.7 1.1-1.4 1.7-2.1.3-.3.3-.6 0-.9-4.6-5.7-9.3-11.4-13.9-17-5.5-6.8-11-13.6-16.5-20.3-5.3-6.7-10.7-13.2-16-19.7C10.7 29 5.5 22.6.2 16.1c0 0-.1-.1-.2-.3C5.8 10.6 11.5 5.3 17.3 0c.8 1 1.6 1.9 2.3 2.8 7 8.6 14.1 17.3 21.1 25.9 6.5 8 13.1 16.1 19.6 24.1C66.8 60.9 73.4 69 79.9 77c4.2 5.1 8.3 10.3 12.5 15.4.3.4.3.6 0 1-5.5 6.7-11 13.5-16.5 20.2-6.3 7.8-12.7 15.6-19 23.4-6.1 7.6-12.3 15.1-18.4 22.7-6.5 8.1-13.1 16.1-19.6 24.2-.5.6-1 1.2-1.6 1.9z'/></svg></button>",
                    responsive: [{
                            breakpoint: 723,
                            settings: {
                                dots: false,
                                arrows: true,
                            }
                        },
                        {
                            breakpoint: 959,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: false,
                                arrows: true,
                            }
                        }
                    ]
                });
            }
            
        })
    }
}