import Ajax from '../modules/ajax';

export default class {
    ajax;

    constructor() {
        this.ajax = new Ajax;
        this.listen();
    }

    setFormSent(form) { 
        $('#modalSuccess').modal('show');
        form.element.querySelectorAll('.label-wrap').forEach(wrap => {
            wrap.classList.remove('filled');
        })
        form.element.querySelectorAll('.form-control').forEach(input => {
            input.value = '';
        });
        form.element.getElementById('customCheckVacancy').checked = false;
        $('#formOrderDemo').modal('hide');

        setTimeout(function() {
            body.classList.add('modal-open');  
        }, 500);
    };

    listen() {
        let orderDemo = document.querySelectorAll('.js_form-order-demo');
        if (orderDemo.length) {
            $(orderDemo).parsley().on('form:submit', (form) => {
                let data = { 
                    action: 'orderDemo',
                    form: {
                        name: form.element.querySelector('[name="name-order-demo"]').value,
                        company: form.element.querySelector('[name="company-order-demo"]').value,
                        phone: form.element.querySelector('[name="phone-order-demo"]').value,
                        email: form.element.querySelector('[name="email-order-demo"]').value,
                    }
                }

                this.ajax.send(data, (response) => {
                    if (response.data.result === 'success') {
                        this.setFormSent(form);
                    }
                });
                return false;
            });
        }
    }
}





