import intlTelInput from "intl-tel-input";
import Inputmask from "inputmask";
import { isUndefined } from "lodash";

export default class {

    mask = [
        {country: 'az', mask: '(999) 999-99-99'},
        {country: 'gb', mask: '99999 999999'},
        {country: 'by', mask: '+375 99 999-99-99'},
        {country: 'ge', mask: '999 99 99 99'},
        {country: 'kz', mask: '(999) 999 9999'},
        {country: 'kg', mask: '9999 999-999'},
        {country: 'ru', mask: '(999) 999-99-99'},
        {country: 'ua', mask: '999 999 9999'},
        {country: 'pt', mask: '(999) 9999999'},
        {country: 'tj', mask: '999 99 9999'},
        {country: 'tm', mask: '9 99 999999'},
        {country: 'uz', mask: '9 99 999 99 99'},
        {mask: '9{*}', default: true}
    ];

    inputs = document.querySelectorAll(".phone-inp");

    constructor() {
        if (!$('.phone-inp').length) {
            return;
        }

        this.inputs.forEach(input => {
            let iti = intlTelInput(input, {
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
                preferredCountries: ["az", "gb", "ua", "ge", "kz", "kg", "ru", "by", "tj", "tm", "uz", "pt"],
                autoHideDialCode: false,
                initialCountry: "auto",
                geoIpLookup: function (success, failure) {
                    $.get("https://ipinfo.io", function () {}, "jsonp").always(function (resp) {
                        var countryCode = (resp && resp.country) ? resp.country : "ru";
                        success(countryCode);
                    });
                },
            });

            input.addEventListener("countrychange", () => {
                let iso = iti.getSelectedCountryData().iso2;
                let mask = this.getMaskByIso(iso);
                input.value = '';
                Inputmask({
                    mask: mask,
                    showMaskOnHover: false,
                }).mask(input);
                if (typeof Livewire === 'object') {
                    Livewire.emit('codeChange', iti.getSelectedCountryData().dialCode);
                }
            });
        })
    }

    getMaskByIso(iso) {
        let local = _.find(this.mask, { country: iso });
        if (_.isUndefined(local)) {
            local = _.find(this.mask, { default: true });
        }
        return local.mask;
    }
}
