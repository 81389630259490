export default class {
    options = {
        offset: 0
    };

    constructor() {
        this.listen();
    }

    listen() {
        $('.cute-link').on('click', (e) => {
            e.preventDefault();
            this.options.offset = $('.cute-link').data('offset') || this.options.offset;
            let id = $('.cute-link').attr('href')|| $(this).data('href');
            let top = $(id).offset().top + this.options.offset;
            $('body,html').animate({
                scrollTop: top
            }, 1000);
        });
    }
}
