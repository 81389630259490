export default class {
    iti = document.querySelector('.iti-mobile');

    constructor() {
        this.listen();
    }

    listen() {
        if (this.iti) {
            this.iti.addEventListener('click', (event) => {
                if (!event.currentTarget.querySelector('.iti--container') || document.querySelector('.ovhidden')) {
                    document.body.classList.remove('ovhidden');
                } else {
                    document.body.classList.add('ovhidden');
                }
            });
        }
    }
}