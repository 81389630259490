import Ajax from '../modules/ajax';

export default class {
    ajax;

    constructor() {
        this.ajax = new Ajax;
        this.listen();
    }

    setFormSent(form) {  
        const modalSuccess = document.getElementById('modalSuccess');
        const text = modalSuccess.querySelector('.modal-body .text');     
        
        form.element.querySelectorAll('.label-wrap').forEach(wrap => {
            wrap.classList.remove('filled');
        })
        form.element.querySelectorAll('.form-control').forEach(input => {
            input.value = '';
        });
        form.element.getElementById('customCheckVacancy').checked = false;
        $('#formResume').modal('hide');

        setTimeout(function() {
            $('#modalSuccess').modal('show');
            text.innerHTML = 'Специалисты отдела кадров свяжутся с вами в ближайшее время';
        }, 500);
    };

    listen() {
        let sendResume = document.querySelectorAll('.js_form-resume');
        if (sendResume.length) {
            $(sendResume).parsley().on('form:submit', (form) => {
                let data = { 
                    action: 'sendResume',
                    form: {
                        name: form.element.querySelector('[name="name-resume"]').value,
                        email: form.element.querySelector('[name="email-resume"]').value,
                        country: form.element.querySelector('[name="country-resume"]').value,
                        city: form.element.querySelector('[name="city-resume"]').value,
                        message: form.element.querySelector('[name="message-resume"]').value,
                        file: form.element.querySelector('[name="file-resume[]"]').value,
                    }
                }

                this.ajax.send(data, (response) => {
                    if (response.data.result === 'success') {
                        this.setFormSent(form);
                    }
                });
                return false;
            });
        }
    }
}





