import Inputmask from "inputmask";
export default class {
    inputTel = document.querySelectorAll('input[type="tel"]');
    inputEmail = document.querySelectorAll('input[type="email"]');

    constructor() {
        this.inputEmail.forEach((mail) => {
            Inputmask({
                mask: "*{1,64}[.*{1,64}][.*{1,64}][.*{1,63}]@-{1,63}.-{1,63}[.-{1,63}][.-{1,63}]",
                greedy: false,
                placeholder: "_@_._",
                showMaskOnFocus: true,
                showMaskOnHover: false,
                casing: "lower",
                onBeforePaste: function (pastedValue, opts) {
                    pastedValue = pastedValue.toLowerCase();
                    return pastedValue.replace("mailto:", "");
                },
                definitions: {
                    "*": {
                        validator: "[0-9\uFF11-\uFF19A-Za-z\u0410-\u044F\u0401\u0451\u00C0-\u00FF\u00B5!#$%&'*+/=?^_`{|}~-]"
                    },
                    "-": {
                        validator: "[0-9A-Za-z-]"
                    }
                }
            }).mask(mail);
        })
    }
}