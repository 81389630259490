export default class {
  rows = 0;
  inputFile = document.querySelectorAll('.js_file-add');
  constructor() {
    if (!document.querySelectorAll(".files").length) {
      return;
    }
    this.listen();
  }

  active() {
    let fileWrap = document.querySelector(".files");
    fileWrap.querySelector(".files__link").classList.remove('disable');
    fileWrap.querySelector(".files__link").setAttribute('for', 'vacansy-resume');
  }

  disable() {
    let fileWrap = document.querySelector(".files");
    fileWrap.querySelector(".files__link").classList.add('disable');
    fileWrap.querySelector(".files__link").removeAttribute('for');
  }

  listen() {
    this.inputFile.forEach((file) => {
      file.addEventListener('change', (element) => {
        let fileName = "";
        let fileWrap = document.querySelector(".files");

        if (element.target.value) {
          fileName = element.target.value.split("\\").pop();
        }          

        if (this.rows >= 2) {
          this.disable();
        } else {
          this.active();
        }

        if (fileName) {
          fileWrap.querySelector(".files__label").insertAdjacentHTML('beforeend', 
            '<div class="files__list">' +
            '<div class="files__added" data-text="' + fileName + '">' + fileName + ' </div>' +
            '<span class="files__delete"></span>' +
            '</div>');
          this.rows++;
        }
      })
    });

    document.querySelector(".files").addEventListener('click', (event) => {
      if (event.target.classList.contains('files__delete')) {
        event.target.parentNode.remove();
        this.rows--;
      }

      if (this.rows < 3) {
        this.active();
      } else {
        this.disable();
      }
    })
  }
}