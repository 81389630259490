export default class {
    selector = '#modalSuccess';
    modal = document.querySelector(this.selector);

    constructor() {
        this.listen();
    }

    show(modalSelector) {
        if (modalSelector) {
            $(modalSelector).modal('hide')
            setTimeout(() => this.show(), 500);
        } else {
            $(this.selector).modal('show');
        }
    }

    hide(modalSelector) {
        $(modalSelector).modal('hide');
    }

    setHeadContent(text) {
        this.modal.querySelector('h5').innerHTML = text;
    }

    setBodyContent(text) {
        this.modal.querySelector('.text').innerHTML = text;
    }

    listen() {
        window.addEventListener('showModal', (...$param) => {
            if ($param[0].detail.head) {
                this.setHeadContent($param[0].detail.head);
            }
            if ($param[0].detail.body) {
                this.setBodyContent($param[0].detail.body);
            }
            this.show($param[0].detail.selector);
        });
    }
}
