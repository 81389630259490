
export default class {
   constructor() {
      if(!$('.js_up').length) {
         return;
     }
      this.listen();
   }
   listen() {
      $('.js_up button').on('click', () => {
         $('body,html').animate({scrollTop: 0}, 500);
      });
      var topPos = $('.js_up').offset().top;
      $(window).scroll(function() {
         var top = $(document).scrollTop();
         if (top > topPos) {
            $('.up').addClass('d-block').fadeIn();
         } else {
            $('.up').removeClass('d-block');
         }
      });
   }
}


