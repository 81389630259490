export default class {

    facebook_links = document.querySelectorAll('.js_facebook-link');
    telegram_links = document.querySelectorAll('.js_telegram-link');
    linkedin_links = document.querySelectorAll('.js_linkedin-link');

    constructor() {
        this.listen();
    }

    actionCallbackCall() {
        if (typeof(ym) == 'function') {
            ym(87129039,'reachGoal','callback-call-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionCallbackContacts() {
        if (typeof(ym) == 'function') {
            ym(87129039,'reachGoal','callback-contacts-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionCallbackOrderDemo() {
        if (typeof(ym) == 'function') {
            ym(87129039,'reachGoal','callback-order-demo-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionClientsForm() {
        if (typeof(ym) == 'function') {
            ym(87129039,'reachGoal','clients-form-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionGetPresentation() {
        if (typeof(ym) == 'function') {
            ym(87129039,'reachGoal','form-get-presentation-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionSubscribeToUsFormSend() {
        if (typeof(ym) == 'function') {
            ym(87129039,'reachGoal','subscribe-to-us-form-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionFacebookLink() {
        if (typeof(ym) == 'function') {
            // ym
        }
        if (typeof (gtag) == 'function') {
            gtag('event', 'Facebook_Link', {
                'event_category': 'Click',
            });
        }
    }
    actionTelegramLink() {
        if (typeof(ym) == 'function') {
            // ym
        }
        if (typeof (gtag) == 'function') {
            gtag('event', 'Telegram_Link', {
                'event_category': 'Click',
            });
        }
    }
    actionLinkedInLink() {
        if (typeof(ym) == 'function') {
            // ym
        }
        if (typeof (gtag) == 'function') {
            gtag('event', 'LinkedIn_Link', {
                'event_category': 'Click',
            });
        }
    }

    listen() {
        window.addEventListener('eventCallbackCall', this.actionCallbackCall);
        window.addEventListener('eventCallbackContacts', this.actionCallbackContacts);
        window.addEventListener('eventCallbackOrderDemo', this.actionCallbackOrderDemo);
        window.addEventListener('eventClientsForm', this.actionClientsForm);
        window.addEventListener('eventGetPresentation', this.actionGetPresentation);
        window.addEventListener('eventSubscribeToUsFormSend', this.actionSubscribeToUsFormSend);
        this.facebook_links.forEach(link => {
            link.addEventListener('click', this.actionFacebookLink);
        });
        this.telegram_links.forEach(link => {
            link.addEventListener('click', this.actionTelegramLink);
        });
        this.linkedin_links.forEach(link => {
            link.addEventListener('click', this.actionLinkedInLink);
        });
    }
}
