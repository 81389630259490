import counterUp from 'counterup2';
require('waypoints/lib/noframework.waypoints');
export default class {
    constructor() {
      const elements = document.querySelectorAll('.counter');

      elements.forEach(el => {
         new Waypoint({
            element: el,
            handler: function() { 
               if (el.innerHTML == 1) {
                  counterUp(el, {
                     action: 'stop'
                  })
               } else {
                  counterUp(el, {
                     duration: 1000,
                     delay: 10
                  }) 
               }               
               this.destroy()
            },  
            offset: 'bottom-in-view',
         });
      });      
   }
}
