import {
    event,
    nodeName
} from "jquery";

export default class {
    constructor() {
        this.slickInit();
        window.addEventListener('resize', function (event) {
            if (!$('.technology-list').hasClass('slick-initialized') && window.innerWidth >= 724) {
                $('.js_technology').slick({
                    dots: true,
                    arrows: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    mobileFirst: false,
                    infinite: false,
                    draggable: false,
                    responsive: [{
                            breakpoint: 1283,
                            settings: {
                                dots: true,
                                arrows: false,
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 723,
                            settings: "unslick"
                        }
                    ]
                });
            } else if ($('.technology-list').hasClass('slick-initialized') && window.innerWidth < 724) {
                console.log($('.technology-list').length);
                $('.js_technology').slick({
                    dots: true,
                    arrows: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    mobileFirst: false,
                    infinite: false,
                    draggable: false,
                    responsive: [{
                            breakpoint: 1283,
                            settings: {
                                dots: true,
                                arrows: false,
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 723,
                            settings: "unslick"
                        }
                    ]
                });
            }
        });
    }
    
    slickInit() {
        $('.js_technology').slick({
            dots: true,
            arrows: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            mobileFirst: false,
            infinite: false,
            draggable: false,
            responsive: [{
                    breakpoint: 1283,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 723,
                    settings: "unslick"
                }
            ]
        });
    }
}