<script>
export default {
  props: ["options", "value"],
  template: "#select2-template1",
  mounted: function () {
    var app = this;
    $(this.$el)
      .select2({
        data: this.options,        
      })
      .val(this.value)
      .trigger("change")
      .on("change", function () {
        app.$emit("input", this.value);
      });
  },
  watch: {
    value: function (value) {
      $(this.$el).val(value).trigger("change");
    },
    options: function (options) {
      $(this.$el).empty().select2({
        data: options,
      });
    },
  },
  destroyed: function () {
    $(this.$el).off().select2("destroy");
  },
};
</script>