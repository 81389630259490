export default class {
   animationSpeed = 150;
   menuParent = $('.js_top-menu-parent');

   constructor() {
      this.listen();
   }
   open(element) {
      let submenu = $(element).find('.submenu');
      element.classList.add('open')
      submenu.fadeIn(this.animationSpeed);
   };
   close(element) {
      let submenu = $(element).find('.submenu');
      element.classList.remove('open')
      submenu.fadeOut(this.animationSpeed);
   };
   listen() {
      this.menuParent.on('mouseenter', (event) => {
         if (!event.currentTarget.classList.contains('open')) {
            this.open(event.currentTarget);
         }
      });
      this.menuParent.on('mouseleave', (event) => {
         if (event.currentTarget.classList.contains('open')) {
            this.close(event.currentTarget);
         }
      });
   };
}