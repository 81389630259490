export default class {
    state = 'closed';
    menu = $('.js_mobile-menu');
    submenu = $('.js_mobile-menu-parent').find('.submenu');

    constructor() {
        this.listen();
    }

    open() {
        $('body').addClass('modal-open');
        $('.js_show-mobile-menu').addClass('is-active');
        $('.js_show-mobile-menu').parent().addClass('is-active');
        this.menu.fadeIn(1);
        this.state = 'opened';
    };
    close() {
        $('.js_show-mobile-menu').removeClass('is-active');
        $('.js_show-mobile-menu').parent().removeClass('is-active');
        this.state = 'closed';
        this.menu.fadeOut(1);
        $('body').removeClass('modal-open');
    };
    openSubmenu(element) {
        element.classList.add('open');
    };
    closeSubmenu(element) {
        element.classList.remove('open');
    };
    listen() {
        $('.js_show-mobile-menu').on('click', () => {
            (this.state === 'closed') ?
            this.open(): this.close();
        });

        document.querySelectorAll('.js_mobile-menu-parent').forEach((node) => {
            node.addEventListener('click', (event) => {
                let submenu = event.currentTarget.querySelector('.submenu');
                if (!submenu.classList.contains('open')) {
                    this.openSubmenu(submenu);
                } else {
                    this.closeSubmenu(submenu);
                }
            })
        });

        $('.js_mobile-menu-parent > a').on('click', (event) => {
            event.preventDefault();
        });
    };
}