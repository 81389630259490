import { forEach } from "lodash";

export default class {
    constructor() {
        this.getMobileOperatingSystem();
        if (this.getMobileOperatingSystem() == 'ios') { 
            document.querySelectorAll('.js_btn-ios').forEach(btnIos => {
                btnIos.style.display = 'flex';
            })
        } 
        else if (this.getMobileOperatingSystem() == 'android') { 
            document.querySelectorAll('.js_btn-android').forEach(btnAnd => {
                btnAnd.style.display = 'flex';
            })
        } 
        else { 
            document.querySelectorAll('.js_btn-other').forEach(btnOther => {
                btnOther.style.display = 'flex';
            })
        } 
        this.listen();
    }

    getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/windows phone/i.test(userAgent)) {
			return "windows";
		}

		if (/android/i.test(userAgent)) {
			return "android";
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "ios";
		}

		return "unknown";
    }

    share(social, url_share){
		var url_soc = false;
		switch (social) {
			case "vk":
				url_soc = "https://vk.com/share.php?url="+url_share;
				break;
			case "fb":
				url_soc = "https://www.facebook.com/sharer/sharer.php?u="+url_share;
				break;
			case "ok":
				url_soc = "https://connect.ok.ru/offer?url="+url_share;
				break;
			case "tw":
				url_soc = "https://twitter.com/intent/tweet?url="+url_share;
				break;
			case "li":
				url_soc = "https://www.linkedin.com/shareArticle?mini=true&url="+url_share;
				break;
			case "pi":
				url_soc = "http://pinterest.com/pin/create/button/?url="+url_share;
				break;
			case "tu":
				url_soc = "http://www.tumblr.com/share/link?url="+url_share;
				break;
		}
		 
		if(url_soc){
			let width = 800, height = 500;
			let left = (window.screen.width - width) / 2;
			let top = (window.screen.height - height) / 2;
			let social_window = window.open(url_soc, "share_window", "height=" + height + ",width=" + width + ",top=" + top + ",left=" + left);
			social_window.focus();
		}
    }  
    // getvcardasfile(id) {

    //     $( "#getdownload_result" ).html( $( "#loader" ).html() );
    //     var posting = $.post( '/getlabeltools/getvcardasfile', { id: id} );

    //     posting.done(function(data) {		
    //             $( "#getdownload_result" ).html('');
    //             $("#downloadFileiFrame").attr("src", data);   
    //         });

    //     posting.fail(function(data) {
    //             $( "#getdownload_result" ).html( '<p class="text-warning">При подготовке данных возникла ошибка сервера!</p>' );    	 	 		  	 		
    //         });				
    // }
    
    listen() {
        document.querySelectorAll('.js_share-btn').forEach(btn => {
            btn.addEventListener('click', () => {
                let social = btn.getAttribute("data-social");
                let url_share = location.href;
                // если задан атрибут data-url, то берем урл из него
                // if (typeof $(this).attr("data-url") !== typeof undefined && $(this).attr("data-url") !== false) {
                //     url_share = $(this).data("url");        
                // }
                this.share(social, url_share);
                console.log(social, url_share);
            })
        }) 
    }
}
