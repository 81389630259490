export default class {
    css(element, animationName, callback) {
        let node = (element instanceof Element) ? element : document.querySelector(element);
        node?.classList.add('animated', animationName);

        function handleAnimationEnd() {
            node.classList.remove('animated', animationName)
            node.removeEventListener('animationend', handleAnimationEnd)

            if (typeof callback === 'function') callback()
        };

        node?.addEventListener('animationend', handleAnimationEnd)
    }
}
